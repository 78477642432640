import { Routes } from '@angular/router';

import { PageNotFoundComponent } from '@ciphr/shared/page-not-found';

export const appRoutes: Routes = [
  {
    path: 'viewer',
    loadComponent: () => import('./report-viewer-page/report-viewer-page.component'),
  },
  {
    path: 'designer',
    loadComponent: () => import('./report-designer-page/report-designer-page.component'),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'viewer',
  },
  { path: '**', component: PageNotFoundComponent },
];
